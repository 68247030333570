export const DelayHydration = () => import('../../node_modules/nuxt-delay-hydration/dist/runtime/components/DelayHydration.vue' /* webpackChunkName: "components/delay-hydration" */).then(c => wrapFunctional(c.default || c))
export const HydrationStatus = () => import('../../node_modules/nuxt-delay-hydration/dist/runtime/components/HydrationStatus.vue' /* webpackChunkName: "components/hydration-status" */).then(c => wrapFunctional(c.default || c))
export const HotAccordion = () => import('../../components/Atoms/Accordion/Accordion.vue' /* webpackChunkName: "components/hot-accordion" */).then(c => wrapFunctional(c.default || c))
export const HotAccordionItem = () => import('../../components/Atoms/AccordionItem/AccordionItem.vue' /* webpackChunkName: "components/hot-accordion-item" */).then(c => wrapFunctional(c.default || c))
export const HotAppStoreButton = () => import('../../components/Atoms/AppStoreButton/AppStoreButton.vue' /* webpackChunkName: "components/hot-app-store-button" */).then(c => wrapFunctional(c.default || c))
export const HotButton = () => import('../../components/Atoms/Button/Button.vue' /* webpackChunkName: "components/hot-button" */).then(c => wrapFunctional(c.default || c))
export const HotIframeSso = () => import('../../components/Atoms/IframeSso/IframeSso.vue' /* webpackChunkName: "components/hot-iframe-sso" */).then(c => wrapFunctional(c.default || c))
export const HotLink = () => import('../../components/Atoms/Link/Link.vue' /* webpackChunkName: "components/hot-link" */).then(c => wrapFunctional(c.default || c))
export const HotImage = () => import('../../components/Atoms/Image/Image.vue' /* webpackChunkName: "components/hot-image" */).then(c => wrapFunctional(c.default || c))
export const HotSocialIcons = () => import('../../components/Atoms/SocialIcons/SocialIcons.vue' /* webpackChunkName: "components/hot-social-icons" */).then(c => wrapFunctional(c.default || c))
export const HotTag = () => import('../../components/Atoms/Tag/Tag.vue' /* webpackChunkName: "components/hot-tag" */).then(c => wrapFunctional(c.default || c))
export const HotTitle = () => import('../../components/Atoms/Title/Title.vue' /* webpackChunkName: "components/hot-title" */).then(c => wrapFunctional(c.default || c))
export const HotVideo = () => import('../../components/Atoms/Video/Video.vue' /* webpackChunkName: "components/hot-video" */).then(c => wrapFunctional(c.default || c))
export const HotOrganicAccordion = () => import('../../components/Atoms/Organic/Accordion/Accordion.vue' /* webpackChunkName: "components/hot-organic-accordion" */).then(c => wrapFunctional(c.default || c))
export const HotOrganicAccordionItem = () => import('../../components/Atoms/Organic/AccordionItem/AccordionItem.vue' /* webpackChunkName: "components/hot-organic-accordion-item" */).then(c => wrapFunctional(c.default || c))
export const HotOrganicAccordionItemCustom = () => import('../../components/Atoms/Organic/AccordionItemCustom/AccordionItemCustom.vue' /* webpackChunkName: "components/hot-organic-accordion-item-custom" */).then(c => wrapFunctional(c.default || c))
export const HotOrganicButton = () => import('../../components/Atoms/Organic/Button/Button.vue' /* webpackChunkName: "components/hot-organic-button" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
