const i18nPages = {
  index: {
    'pt-br': '/',
    en: '/',
    es: '/',
    'es-mx': '/',
    'es-co': '/',
    'es-es': '/',
    fr: '/',
    ja: '/',
    it: '/',
  },
  about: {
    'pt-br': '/sobre-nos',
    en: '/about',
    es: '/sobre',
    'es-mx': '/sobre',
    'es-co': '/sobre',
    'es-es': '/sobre',
    fr: '/propos-de-nous',
    ja: encodeURI('/について'),
    it: '/chi-siamo',
  },
  affiliates: {
    'pt-br': '/afiliados',
    en: '/affiliates',
    es: '/afiliados',
    'es-mx': '/afiliados',
    'es-co': '/afiliados',
    'es-es': '/afiliados',
    fr: '/affilies',
    ja: encodeURI('/アフィリエイター'),
    it: '/affiliazione',
  },
  'members-area/index': {
    'pt-br': '/area-de-membros',
    en: '/members-area',
    es: '/area-de-miembros',
    'es-mx': false,
    'es-es': false,
    'es-co': false,
    fr: '/zone-des-membres',
    ja: false,
    it: false,
  },
  'members-area/thank-you': {
    'pt-br': '/area-de-membros/obrigado',
    en: '/members-area/thank-you',
    es: '/area-de-miembros/gracias',
    'es-mx': false,
    'es-es': false,
    'es-co': false,
    fr: false,
    ja: false,
    it: false,
  },
  payments: {
    'pt-br': '/pagamentos',
    en: '/payments',
    es: '/pagos',
    'es-co': '/pagos',
    'es-mx': '/pagos',
    'es-es': '/pagos',
    fr: '/paiements',
    ja: false,
    it: false,
  },
  pricing: {
    en: '/pricing',
    'es-es': '/precios',
    fr: '/prix',
    'pt-br': false,
    es: false,
    'es-mx': false,
    'es-co': false,
    ja: false,
    it: false,
  },
  producers: {
    'pt-br': '/produtores',
    en: '/producers',
    es: '/productores',
    'es-co': '/productores',
    'es-mx': '/productores',
    'es-es': '/productores',
    fr: '/producteurs',
    ja: false,
    it: false,
  },
  solutions: {
    'pt-br': '/solucoes',
    en: '/solutions',
    es: '/soluciones',
    'es-mx': '/soluciones',
    'es-co': '/soluciones',
    'es-es': '/soluciones',
    fr: '/solutions',
    ja: encodeURI('/ソリューション'),
    it: '/funzionalita',
  },
  'hotmart-app': {
    'pt-br': '/app-da-hotmart',
    en: '/hotmart-app',
    es: '/app-de-hotmart',
    'es-mx': '/app-de-hotmart',
    'es-co': '/app-de-hotmart',
    'es-es': '/app-de-hotmart',
    fr: false,
    ja: false,
    it: false,
  },
  'tour/index': {
    'pt-br': '/tour',
    en: '/tour',
    es: '/tour',
    'es-mx': '/tour',
    'es-co': '/tour',
    'es-es': '/tour',
    fr: '/tour',
    ja: false,
    it: false,
  },
  'tour/_id': {
    'pt-br': '/tour/:id',
    en: '/tour/:id',
    es: '/tour/:id',
    'es-mx': '/tour/:id',
    'es-co': '/tour/:id',
    'es-es': '/tour/:id',
    fr: '/tour/:id',
    ja: false,
    it: false,
  },
  sitemap: {
    'pt-br': '/mapa-do-site',
    en: false,
    es: false,
    'es-mx': false,
    'es-co': false,
    'es-es': false,
    fr: false,
    ja: false,
    it: false,
  },
  'is-hotmart-for-me': {
    'pt-br': false,
    en: '/is-hotmart-for-me',
    es: false,
    'es-mx': false,
    'es-co': false,
    'es-es': false,
    fr: false,
    ja: false,
    it: false,
  },
  'sales-accelerator': {
    'pt-br': '/acelerador-de-vendas',
    en: false,
    es: false,
    'es-mx': false,
    'es-co': false,
    'es-es': false,
    fr: false,
    ja: false,
    it: false,
  },
  'members-area/paid-media': {
    'pt-br': '/area-de-membros/midias-pagas',
    en: false,
    es: false,
    'es-mx': false,
    'es-es': false,
    'es-co': false,
    fr: false,
    ja: false,
    it: false,
  },
  'lp-producer': {
    'pt-br': '/produtor-interessado',
    en: false,
    es: false,
    'es-mx': false,
    'es-co': false,
    'es-es': false,
    fr: false,
    ja: false,
    it: false,
  },
}

export default i18nPages
